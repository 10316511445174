module.exports = {
  locales: ['en', 'es'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'header', 'footer', 'metainfo'],
    '/': ['home'],
    '/contact': ['contact'],
    '/404': ['not-found'],
  },
  loadLocaleFrom: (lang, ns) =>
    import(`./public/locales/${lang}/${ns}.json`).then((m) => m.default),
};
