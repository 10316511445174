
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@kronoss/styles/main.scss';
import type { AppProps } from 'next/app';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { Inter } from 'next/font/google';
import { ToastBar, Toaster } from 'react-hot-toast';
export const primaryFont = Inter({
    subsets: ['latin', 'cyrillic'],
    variable: '--font-primary',
});
const theme = createTheme({
    typography: {
        fontFamily: primaryFont.style.fontFamily,
    },
});
function App({ Component, pageProps }: AppProps) {
    return (<ThemeProvider theme={theme}>
      <style jsx global>{`
        input,
        textarea,
        select,
        button {
          font-family: ${primaryFont.style.fontFamily};
        }
      `}</style>
      <CssBaseline />
      <Component {...pageProps}/>
      <Toaster toastOptions={{
            position: 'top-right',
        }} containerClassName={'hot-toast'}>
        {(t) => <ToastBar toast={t}/>}
      </Toaster>
    </ThemeProvider>);
}

    export default __appWithI18n(App, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  